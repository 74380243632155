import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import type { OccasionTimeFragment } from '../../../types/graphql-types';
import localStyles from './OccasionDateIcon.module.pcss';
import usePreferredTimezone from '@aurora/shared-client/components/usePreferredTimezone';

interface Props {
  /**
   * The occasion to display the date icon for.
   */
  occasion: OccasionTimeFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 * Display icon for occasion date.
 * @author Aditi Agarwal
 */
const OccasionDateIcon: React.FC<React.PropsWithChildren<Props>> = ({ occasion, className }) => {
  const cx = useClassNameMapper(localStyles);
  const { startTime } = occasion || {};
  const { formatDate } = useIntl();

  const { data: timezoneData, loading: timezoneLoading } = usePreferredTimezone();

  if (timezoneLoading) {
    return null;
  }

  const userPreferredTimeZone = timezoneData?.self.profileSettings.timeZone?.value;

  const occasionStartMonth = formatDate(startTime, {
    timeZone: userPreferredTimeZone,
    month: 'short'
  });

  const occasionStartDate = formatDate(startTime, {
    timeZone: userPreferredTimeZone,
    day: 'numeric'
  });

  return occasionStartDate ? (
    <div className={cx(className, 'lia-date-icon')} data-testid="OccasionDateIcon">
      <div className={cx('lia-month')}>{occasionStartMonth}</div>
      <div className={cx('lia-date')}>{occasionStartDate}</div>
    </div>
  ) : null;
};

export default OccasionDateIcon;
