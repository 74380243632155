import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { MessageModerationDataFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * The message to display the content workflow state for
   */
  message: MessageModerationDataFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 * Message Publish Status
 * @author Aditi Agarwal
 */
const MessagePublishStatus: React.FC<React.PropsWithChildren<Props>> = ({ message, className }) => {
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_PUBLISH_STATUS
  );

  if (textLoading) {
    return null;
  }

  return (
    <span className={cx(className)} data-testid="MessagePublishStatus">
      {formatMessage(message?.visibilityScope)}
    </span>
  );
};

export default MessagePublishStatus;
