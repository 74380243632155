import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type {
  Article,
  Message,
  UserConnection
} from '@aurora/shared-generated/types/graphql-schema-types';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type {
  MessageContributorsQuery,
  MessageContributorsQueryVariables
} from '../../../types/graphql-types';
import localStyles from './MessageContributors.module.pcss';
import messageContributorsQuery from './MessageContributors.query.graphql';

const MessageContributorsModalView = dynamic(
  () =>
    import(
      /* webpackChunkName: "modal-MessageContributorsModalView" */ '../MessageView/MessageContributorsModalView/MessageContributorsModalView'
    ),
  { ssr: false }
);

interface Props {
  /**
   * The message to show the contributors of.
   */
  message: Pick<Message, 'id'>;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 *  To display the contributors of a message.
 *
 *  @author Vishnu Das
 */

const MessageContributors: React.FC<React.PropsWithChildren<Props>> = ({ message, className }) => {
  const cx = useClassNameMapper(localStyles);
  const [showContributors, setShowContributors] = useState(false);

  const { data: contributorsData, loading: contributorsLoading } = useQueryWithTracing<
    MessageContributorsQuery,
    MessageContributorsQueryVariables
  >(module, messageContributorsQuery, {
    variables: {
      id: message.id
    }
  });

  if (contributorsLoading || !contributorsData) {
    return null;
  }

  const contributorsLength =
    ((contributorsData.message as Article)?.contributors as UserConnection)?.edges.length ?? 0;

  if (contributorsLength === 0) {
    return null;
  }

  return (
    <>
      <span
        role="button"
        tabIndex={0}
        className={cx(className, 'lia-message-contributors-count')}
        onClick={() => {
          setShowContributors(true);
        }}
        onKeyDown={(event: React.KeyboardEvent): void => {
          if (event.key === 'Enter') {
            setShowContributors(true);
          }
        }}
        data-testid="MessageContributors"
      >
        {contributorsLength > 1 && `+${contributorsLength - 1}`}
      </span>
      <MessageContributorsModalView
        show={showContributors}
        closeCallback={() => setShowContributors(false)}
        message={message}
      />
    </>
  );
};

export default MessageContributors;
